@import '/node_modules/normalize.css/normalize.css';
@import "./fonts.scss";
@import "./tweakpane.scss";

@mixin cardWidthBreakpoint($point) {
  @if $point == 910 {
    @container card (min-width: 909px) and (max-width: 911px) { @content; }
  }
}

:root{
  --color-bg: #202026;
  --color-text: #ffffff;
  --color-table-border: #AEAEAE;
  --table-row-padding-v: 0px;
  --table-row-padding-l: 0px;
  --table-columns-1: 54px;
  --table-columns-2: 66px;
  --table-columns-3: 242px;
  --table-columns-4: 130px;
  --table-columns-3-pl: 10px;
  --table-columns-4-pl: 10px;
  --table-columns-910-1: 36px;
  --table-columns-910-2: 48px;
  --table-columns-910-3: 188px;
  --table-columns-910-4: 103px;
  --table-columns-910-3-pl: 10px;
  --table-columns-910-4-pl: 10px;
}
html{

}
body{
  &.html2canvas{
    background: var(--color-bg);
  }
}
html, body, #app{
  width: 100%;
  height: 100%;
}
canvas { outline: none; border: none;
  padding: 0;
  margin: 0; }

.wrapper{
  width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card{
  --card-width: 1200px;
  --td-font-weight: 400;
  --td-font-style: 'normal';
  container-name: card;
  container-type: inline-size;
  background: var(--color-bg);
  display: block;
  margin: 0 auto;
  width: var(--card-width);
  box-sizing: border-box;
  font-family: 'Suisse', Arial;
  color: var(--color-text);
  position: relative;
  overflow: hidden;
  &__iw{
    box-sizing: border-box;
    padding: 64px 74px 83px;
    min-height: 628px;
    @include cardWidthBreakpoint(910) {
      padding: 48px 65px 75px;
      min-height: 512px;
    }
  }
  &__header{
    margin-bottom: 50px;
    @include cardWidthBreakpoint(910) {
      margin-bottom: 57px;
    }
  }
  &__content{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 48px;
    @include cardWidthBreakpoint(910) {
      grid-gap: 29px;
    }
  }
}

.header{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  letter-spacing: 0.02em;
  line-height: 1;
  &__title{
    font-size: 42px;
    margin: 0;
    font-weight: 400;
    i{
      font-weight: 300;
    }
    @include cardWidthBreakpoint(910) {
      font-size: 34px;
    }
  }
  &__date{
    font-size: 28px;
    margin-bottom: 3px;
    @include cardWidthBreakpoint(910) {
      font-size: 22px;
      margin-bottom: 2px;
    }
  }
  &__logo{
    &-svg{
      display: block;
      width: 117px;
      height: auto;
      margin-bottom: 6px;
      @include cardWidthBreakpoint(910) {
        width: 99px;
      }
    }
  }
}

.table{
  line-height: 1.1;
  letter-spacing: 0.02em;
  --table-columns: var(--table-columns-1) var(--table-columns-2) var(--table-columns-3) var(--table-columns-4);
  --table-row-height: 66px;
  @include cardWidthBreakpoint(910) {
    --table-row-height: 51px;
    --table-columns: var(--table-columns-910-1) var(--table-columns-910-2) var(--table-columns-910-3) var(--table-columns-910-4);
  }

  &__row{
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    height: calc(var(--table-row-padding-v) * 2 + var(--table-row-height));
    border-bottom: 1px solid var(--color-table-border);
    box-sizing: border-box;
    &--default{
      grid-template-columns: var(--table-columns);
      padding-top: var(--table-row-padding-v);
      padding-bottom: var(--table-row-padding-v);
    }
    &--header{
      grid-template-columns: var(--table-columns);
      height: auto;
      border-bottom: 4px solid var(--color-table-border);
      @include cardWidthBreakpoint(910) {
        display: flex;
        align-items: center;
        border-bottom: 3px solid var(--color-table-border);
      }
    }
  }
  &__th{
    font-size: 28px;
    padding-bottom: 20px;
    display: grid;
    white-space: nowrap;
    grid-template-columns: var(--table-columns);
    @include cardWidthBreakpoint(910) {
      display: flex;
      align-items: center;
      font-size: 22px;
      padding-bottom: 14px;
    }
    &--empty{
      @include cardWidthBreakpoint(910) {
        display: none
      }
    }
    &--title{
      padding-left: var(--table-columns-3-pl);
      @include cardWidthBreakpoint(910) {
        padding-left: 49px;
      }
    }
    &--apr{
      padding-left: var(--table-columns-4-pl);
      @include cardWidthBreakpoint(910) {
        margin-left: auto;
      }
    }
  }
  &__td{
    max-height: 100%;
    font-weight: var(--td-font-weight);
    font-style: var(--td-font-style);
    display: flex;
    align-items: center;
    justify-content: center;
    &--number{
      font-size: 28px;
      @include cardWidthBreakpoint(910) {
        font-size: 22px;
      }
    }
    &--image{
      svg{
        overflow: hidden;
        display: block;
        width: 43px;
        height: auto;
        @include cardWidthBreakpoint(910) {
          width: 32px;
        }
      }
    }
    &--title{
      justify-content: flex-start;
      font-size: 28px;
      padding-left: var(--table-columns-3-pl);
      @include cardWidthBreakpoint(910) {
        font-size: 21px;
        padding-left: var(--table-columns-910-3-pl);
      }
    }
    &--apr{
      justify-content: flex-start;
      font-size: 32px;
      padding-left: var(--table-columns-4-pl);
      @include cardWidthBreakpoint(910) {
        font-size: 23px;
        padding-left: var(--table-columns-910-4-pl);
      }
    }
  }

  &__row-number{
    padding-left: var(--table-row-padding-l);
  }
  &__row-image{

  }
  &__row-title{
    opacity: 0;
    box-sizing: border-box;
    height: var(--table-row-height);
    display: flex;
    align-items: center;
  }
  &__row-apr{

  }
}
