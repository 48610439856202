@font-face {
  font-family: 'Suisse';
  src: url('../fonts/suisse/SuisseIntl-Light.woff2') format('woff2'),
  url('../fonts/suisse/SuisseIntl-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Suisse';
  src: url('../fonts/suisse/SuisseIntl-LightItalic.woff2') format('woff2'),
  url('../fonts/suisse/SuisseIntl-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Suisse';
  src: url('../fonts/suisse/SuisseIntl-Regular.woff2') format('woff2'),
  url('../fonts/suisse/SuisseIntl-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Suisse';
  src: url('../fonts/suisse/SuisseIntl-Italic.woff2') format('woff2'),
  url('../fonts/suisse/SuisseIntl-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

//@font-face {
//  font-family: 'Suisse';
//  src: url('../fonts/suisse/SuisseIntl-Medium.woff2') format('woff2'),
//  url('../fonts/suisse/SuisseIntl-Medium.woff') format('woff');
//  font-weight: 500;
//  font-style: normal;
//  font-display: swap;
//}

@font-face {
  font-family: 'Suisse';
  src: url('../fonts/suisse/SuisseIntl-SemiBold.woff2') format('woff2'),
  url('../fonts/suisse/SuisseIntl-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Suisse';
  src: url('../fonts/suisse/SuisseIntl-SemiBoldItalic.woff2') format('woff2'),
  url('../fonts/suisse/SuisseIntl-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
