#tweakpaneContainer{
  position: absolute;
  right: 10px;
  top: 10px;
  resize: horizontal;
  overflow: auto;
  border: 1px solid black;
  direction: rtl;
  container-name: tweakpane;
  container-type: inline-size;
  min-width: 300px;
  .tp-rotv{
    direction: ltr;
  }
  .tp-dfwv{
    min-width: 300px!important;
  }
  .tp-lblv_v{
    @container tweakpane (min-width: 310px) {
      flex-grow: 1!important;
    }
  }
}